/* Global dependencies */
@import "dependencies/entypo";
@import "dependencies/mixins";

body {
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth:always;
}

.jumbotron {
  background: url('../img/landing/bg.jpg') center bottom no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.jumbotron * .btn {
  width: 40%;
  margin-top: 25px;
  text-transform: uppercase;
  @include border-radius(25px);
  font-weight: 700;
  font-size: 15px;
  position: relative;
  padding: 11px 12px 9px;
  transition: .4s all ease-in-out;
  box-shadow: 0 0 20px 4px rgba(10, 15, 20, 0.1);
  letter-spacing: 1px;
}

.jumbotron * .btn:before {
  opacity: 0;
  content: "\e63d";
  font-family: 'icomoon';
  position: absolute;
  right: 15px;
  top: 9px;
  font-size: 18px;
  transition: .4s opacity ease-in-out;
}

.jumbotron * .btn:hover {
  padding-left: 0px !important;
}

.jumbotron * .btn:hover:before {
  opacity: 1;
}

.jumbotron * .btn-default {
  margin-right: 5%;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.jumbotron * .btn-success {
  border: 2px solid #5cb85c;
}

.jumbotron * .btn-success:hover {
  border-color: #449d44;
}

h1 {
  font-weight: 300;
  margin-top: 25px;
}

.navbar {
  background: transparent;
}

.sub-cta {
  font-family: "open-sans",sans-serif;
  font-weight: 400;
  background: #333333;
  text-align: left;
  padding: 30px 0;
  color: #81878c;
  font-size: 1.5rem;
  line-height: 2.6rem;
}

.sub-cta p {
  display: inline-block;
  margin: 0px;
  font-size: 1.5rem;
  padding-top: 2px;
}

.sub-cta .label {
  position: relative;
  top: -2px;
}

.sub-cta a {
  color: #333333;
  font-weight: 600;
  margin-left: 10px;
  background: #fff;
  border-radius: 25px;
  padding: 5px 15px;
}

.label.label-blue {
  font-family: "proxima-nova", sans-serif;
  background: #252525;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3em;
  padding: .4em .6em;
  border-radius: 2px;
  letter-spacing: .15em;
  margin: 0 1em 0 0;
  text-indent: 1px;
}

h2 {
  font-size: 34px;
  line-height: 48px;
  font-weight: 300;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 26px;
  color: #81878c;
}

h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 100;
  text-transform: uppercase;
}

.feature-1 {
  padding: 75px;
  background: #fff;
}

.feature-2 {
  padding: 75px;
  background-color: rgb(248, 248, 248);
}

.btn-link {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  padding-left: 0px;
  margin-top: 15px;
}

.btn-link i {
  position: relative;
  top: 2px;
}

.quote {
  margin-top: 25px;
  padding: 0;
}

.quote p {
  font-style: italic;
  padding-left: 20px;
  padding-top: 12px;
  overflow: hidden;
  line-height: 22px;
  font-size: 13px;
  font-family: "open-sans",sans-serif;
  font-weight: 400;
}

.quote p span {
  font-style: normal;
  color: #333;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  margin-top: -15px;
}

.header-bar {
  padding: 5px 0;
  background: rgba(0,0,0,.4);
  color: #fff;
  position: absolute;
  top:0;
  right:0;
  left:0;
  text-transform: uppercase;
  font-weight: 600;
}

.navbar {
  margin-top: 20px;
}

.navbar-brand {
  font-size: 26px;
  color: #fff;
}

.navbar-brand:hover {
  color: #fff !important;
}

.navbar-nav {
  margin-top: 15px;
}

.navbar-nav>li {
  margin-left: 10px;
}

.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: transparent !important;
  opacity: .5;
}

.navbar-nav>li>a {
  color: #fff;
  font-weight: 600;
  padding: 5px 15px;
  opacity: 1;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.navbar-nav>li.highlight {
  background: transparent;
  border: 2px solid #fff;
  margin-top: -1px;
  padding: 0px 19px 0px 10px;
  margin-left: 25px;
  border-radius: 25px;
}

.navbar-nav>li.highlight:after {
  content: "\e63d";
  font-family: 'icomoon';
  position: absolute;
  right: 6px;
  top: 3px;
  font-size: 18px;
}

.cta-email {
  margin: 75px 0;
}

.cta-email .form-control:first-child {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 45px;
  padding-left: 25px;
}

.cta-email .input-group-btn:last-child>.btn {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 45px;
  font-weight: 700;
  padding-right: 25px;
}

footer {
  background: #1a1a1a;
  color: #fff;
  padding: 35px 0;
}

/* Registration */
.header {
  background: #000;
  margin: 0px;
  height: 200px;
  -webkit-box-shadow: inset 0 0 100px rgba(0,0,0,.5);
  box-shadow: inset 0 0 100px rgba(0,0,0,.5);
  background: url('../img/landing/bg.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  padding:0px;
}

.header-inner {
  background: url('../img/pattern.png') rgba(77,157,224,.4);
  height: 200px;
  text-align: center;
}

p strong {
  font-weight: 600;
}

.registration-logo {
  margin-top: -100px;
  margin-bottom: 50px;
}

h5 {
  text-transform: uppercase;
  font-size: 11px;
}

span.ibg {
  -webkit-border-radius: 130px;
  -moz-border-radius: 130px;
  border-radius: 130px;
  background: #009DDC;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 23px;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  float:left;
  margin-top: 2px;
}

.sub-points h3 {
  padding-top: 15px;
  font-weight: 600;
  text-transform: none;
  overflow: hidden;
}

.sub-points p {
  font-size: 16px;
}

footer ul {
  list-style-type: none;
  padding: 0px;
}

footer ul > li > a {
  color: #fff;
  line-height: 24px;
}

footer h3 {
  font-size: 14px;
  font-weight: 800;
}

footer h5 {
  font-weight: 700;
}

footer * span.status {
  @include border-radius(50px);
  width:10px;
  height: 10px;
  background: #333;
  margin-top: 5px;
  display: block;
}

footer * span.status.open {
  background: #5cb85c;
}

footer * span.status.open:after {
  content: "NOW OPEN";
  font-weight: 800;
  font-size: 12px;
  margin-left: 15px;
  margin-top: -3px;
  display: inline-block;
  float:left;
  width: 100px;
}

.pricing-header {
  background: url('../img/pricing-background.jpg') center -400px no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 20px;
  margin-top: 0px;
  padding-bottom: 1px;
  position: relative;
  color: #fff;
}

.pricing-header h2 {
  font-weight: 600;
  color: #fff;
}

.pricing {
  background: #fff;
  padding: 10px 25px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #333;
  margin-bottom: -100px;
}

.pricing h3 {
  font-weight: 600;
  color: #333;
  text-transform: none;
  text-align: center;
}

.pricing li:before
{
  content: "\e63a";
  font-family: 'icomoon';
  color: #009DDC;
  font-size: 18px;
  margin-left: 0px;
  margin-right: 10px;
}

.pricing ul
{
  padding-left: 0px;
  text-indent: 2px;
  list-style: none;
  list-style-position: outside;
}

.pricing-header .nav-tabs > li, .nav-pills > li {
  float:none;
  display:inline-block;
  *display:inline; /* ie7 fix */
  zoom:1; /* hasLayout ie7 trigger */
}

.pricing-header .nav-tabs {
  border-bottom: none;
}

.pricing-header .nav-tabs, .nav-pills {
  text-align:center;
}

.plan {
  margin: 0 auto;
  border: 1px solid #E3E6E6;
  box-shadow: 0 1px 2px rgba(0,0,0,.03);
  border-radius: 5px;
  padding: 0 0 34px;
  background: #fff;
  text-align: center;
  float:left;
  height: 475px;
}

.plan-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 27px;
}

.plan:first-of-type {
  margin-right: 50px;
}

.plan-header {
  position: relative;
  border-bottom: solid 1px #E8EBEB;
  margin-bottom: 1em;
  display: table;
  width: 100%;
  height: 60px;
}

.plan-header h4 {
  display: table-cell;
  vertical-align: middle;
  font-size: 21px;
  line-height: 1.1;
  font-size: 2.1rem;
  color: #2F363F;
  font-weight: 600;
  text-align: center;
}

.plan .price {
  font-size: 50px;
  font-size: 5rem;
  color: #2F363F;
  margin: 24px 0 0;
  font-family: "proxima-nova",sans-serif;
  font-weight: 500;
  line-height: 1em;
}

.plan .price sup {
  font-size: 24px;
  top: -18px;
  font-weight: 600;
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

.plan .price sub {
  font-size: 16px;
  top: 0;
  font-weight: 600;
  position: relative;
  bottom: -.25em;
  line-height: 0;
  vertical-align: baseline;
}

.plan-features {
  position: relative;
  margin-bottom: 2em;
  padding: 20px;
  line-height: 25px;
  line-height: 3.7rem;
  text-align: left;
  font-size: 15px;
}

.plan-features i {
  display: block;
  float:left;
  margin-top: 9px;
  margin-right: 2px;
}

.price-other-cycle {
  margin-bottom: 1.4em;
}

.btn-primary {
  background: #009DDC;
  border-color: #009DDC;
  font-weight: 700;
  font-size: 14px;
  border-radius: 25px;
  width: auto;
}

.price-cta .btn-primary {
  font-size: 18px;
  margin: 0px 25px 0;
}

.plan p.muted {
  font-size: 12px;
  color: #1a1a1a;
  margin-bottom: -25px;
}

.price-faq h5 {
  font-size: 13px;
  letter-spacing: .186em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.price-faq h2 {
  margin-top: 0;
}

ol li {
  font-size: 18px;
  line-height: 26px;
  color: #81878c;
  margin-top: 15px;
}

ol li:first-of-type {
  margin-top: 0px;
}

.social-btn {
  padding: 5px 15px;
  margin-bottom: 10px;
  color:#fff;
  font-weight: 700;
  background: #333 !important;
  @include border-radius(3px);
}

.social-btn:last-of-type {
  margin-bottom: 0px;
}

.social-btn i {
  display:block;
  float:left;
  margin-top: 5px;
  margin-right: 10px;
}

.social-btn.twitter {
  background: #00aced;
}

.social-btn.facebook {
  background: #3b5998;
}

.social-btn.linkedin {
  background: #007bb6;
}

.social-btn.google {
  background: #dd4b39;
}

.social-btn a:hover {
  text-decoration:none;
  opacity: .5;
  color: #fff;
}






@media(max-width:767px){

  .navbar-collapse {
    margin-top: 35px;
    background: rgba(255,255,255,.1);
    padding-right: 25px;
    padding-bottom: 15px;
  }

  .sub-cta a {
    margin-left: 0px;
  }

  .sub-cta p {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .feature-1 {
    padding: 25px;
  }

  .feature-1 .btn-link {
    margin-bottom: 25px;
  }

  .feature-2 {
    padding: 25px;
  }

}

@media(max-width:1250px){
  .pri-cta {
    margin-top: -90px !important;
    margin-bottom: 45px !important;
  }
}